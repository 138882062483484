import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../components/breadcrumb/BreadcrumbItem';
import Portlet from '../../components/portlet/Portlet';
import { AxiosResponse } from 'axios';
import { InternationalizationContext } from '../../context/InternationalizationProvider';
import { getDefinitions } from "../../storage/QuestionnaireDefinitions";
import { FhirEntry, FhirEntryResource, FhirItem, FhirItemAnswerOption, IdentifierSystem } from "../model/fhir";
import { QuestionnaireScore } from "./model/model";
import { AuthGet } from "../../services/axios";

const QuestionnaireAnsweredScreen: React.FC = () => {
    const {
        patientId,
        type,
        questionnaireId
    } = useParams<{ patientId: string, type: string, questionnaireId: string }>();

    const context = useContext(InternationalizationContext);
    const getQuestionnaireDefinition = (): FhirEntryResource | undefined => {
        const all = getDefinitions();
        const localeType = type.substring(0, type.length - 2) + context.locale
        const q = all.find(d => d.resource.identifier.find(i => i.system === IdentifierSystem)!.value === localeType)
        return !!q ? q.resource : undefined
    }
    const questionnaireQ = getQuestionnaireDefinition();
    console.log(questionnaireQ)
    const [questionnaireA, setQuestionnaireA] = useState<FhirEntry>();
    const [questionnaireScore, setQuestionnaireScore] = useState<string>();

    useEffect(() => {
        if (!!questionnaireA) {
            AuthGet('origin=hcp&destination=fhir&data[resourceType]=QuestionnaireScore&data[identifier]=' + patientId + '&data[questionnaire]=' + type)
                .then((res: AxiosResponse<QuestionnaireScore[]>) => {
                    if (!res.data || res.data.length === 0) {
                        return;
                    }

                    const resource = res.data.find((s: QuestionnaireScore) => s.qresp_id === questionnaireA.resource.id)
                    if (!resource) {
                        return
                    }

                    setQuestionnaireScore(resource.score.toFixed(2) + "")
                })
                .catch(err => console.error(err));
        }
    }, [patientId, questionnaireA, type])

    const getQuestionnaireResponse = React.useCallback(() => {
        AuthGet('origin=hcp&destination=fhir&data[resourceType]=QuestionnaireResponse&data[identifier]=' + patientId + '&data[questionnaire]=' + type)
            .then(res => {
                if (!res.data.entry) {
                    setShowNotification(true);
                    return;
                }

                const foundAnsweredQuestionnaire = res.data.entry.find((e: any) => e.resource.id === questionnaireId);
                console.log(foundAnsweredQuestionnaire);
                if (!foundAnsweredQuestionnaire) {
                    setShowNotification(true);
                    return;
                }
                setShowNotification(false);
                setQuestionnaireA(foundAnsweredQuestionnaire);

            })
            .catch(err => console.error(err));
    }, [patientId, questionnaireId, type]);

    useEffect(() => {
        setQuestionnaireA(undefined)
        getQuestionnaireResponse();
    }, [getQuestionnaireResponse, context.locale, patientId, questionnaireId]);

    const [showNotification, setShowNotification] = useState<boolean>(false);

    const isChecked = (linkId: string, code: string) => {
        const answer = questionnaireA!.resource.item.find(a => a.linkId === linkId)
        if (!answer || !answer.answer || !answer.answer[0]) {
            return false;
        }
        return answer.answer[0].valueCoding.code === code
    }

    const getSliderValue = (linkId: string) => {
        const answer = questionnaireA!.resource.item.find(a => a.linkId === linkId)
        return answer!.answer[0].valueCoding.code
    }

    return (
        <React.Fragment>
            <div className="mb-4">
                <Breadcrumb>
                    <BreadcrumbItem to="/secure/dashboard">Dashboard</BreadcrumbItem>
                    {!!patientId && <React.Fragment>
                        <BreadcrumbItem to={"/secure/patients"}>Patients</BreadcrumbItem>
                        <BreadcrumbItem
                            to={"/secure/patients/" + patientId + '/questionnaires'}>Patient</BreadcrumbItem>
                        <BreadcrumbItem>Questionnaire</BreadcrumbItem>
                    </React.Fragment>}
                    {!patientId && <React.Fragment>
                        <BreadcrumbItem to={"/secure/questionnaires"}>Questionnaires</BreadcrumbItem>
                        <BreadcrumbItem>Questionnaire</BreadcrumbItem>
                    </React.Fragment>}
                </Breadcrumb>
            </div>

            <div className="my-4">
                <Portlet title={!!questionnaireQ ? questionnaireQ.title : 'Questionnaire'}
                         isLoading={!questionnaireA}
                         subtitle={!!questionnaireScore ? "Score: " + questionnaireScore : undefined}
                >
                    {!questionnaireQ && <div className="notification is-danger is-light m-3">
                        Couldn't load Questionnaire definition.
                    </div>}

                    {showNotification && <div className="notification is-link is-light m-3">
                        This questionnaire is not answered!
                    </div>}

                    {!showNotification && !!questionnaireQ && !!questionnaireA && <React.Fragment>
                        {!!questionnaireQ && questionnaireQ.item.map((s: FhirItem, index: number) => {
                            return <React.Fragment key={s.linkId}>
                                {index !== 0 && <hr/>}
                                <div>
                                    <p className="title is-5">{s.linkId}</p>
                                    <p className="subtitle is-5">
                                        <div dangerouslySetInnerHTML={{__html: s.text}}/>
                                    </p>
                                    <hr/>
                                    <div className="columns is-multiline">
                                        {!!s.item && s.item.map((q: FhirItem, index2: number) => {
                                            return <React.Fragment key={q.linkId}>
                                                <div className="column is-half">
                                                    <p className="subtitle is-5">{index2 + 1}.&nbsp;{q.text}</p>
                                                    {q.maxLength === 1 ? <div>

                                                            <span
                                                                className="is-pulled-left"><label>{q.answerOption![0].valueCoding.display}</label></span>
                                                            <span
                                                                className="is-pulled-right"><label>{q.answerOption![1].valueCoding.display}</label></span>
                                                            <span className=""><input
                                                                className="slider is-fullwidth is-static"
                                                                step="1"
                                                                min={q.answerOption![0].valueCoding.code}
                                                                name={index + "_q_" + q.answerOption![0].valueCoding.code}
                                                                max={q.answerOption![1].valueCoding.code}
                                                                disabled
                                                                value={getSliderValue(q.linkId)} type="range"/></span>


                                                        </div>
                                                        : <div>
                                                            {!!q.answerOption && q.answerOption.map((a: FhirItemAnswerOption) => {
                                                                return <React.Fragment key={a.valueCoding.code}>
                                                                    <div className="control">
                                                                        <input type="radio"
                                                                               name={index + "_q_" + a.valueCoding.code}
                                                                               className="is-static"
                                                                               disabled
                                                                               checked={isChecked(q.linkId, a.valueCoding.code)}
                                                                        />
                                                                        <label
                                                                            className="radio">&nbsp;{a.valueCoding.display}</label>

                                                                    </div>
                                                                </React.Fragment>
                                                            })}
                                                        </div>}
                                                </div>
                                            </React.Fragment>
                                        })}
                                    </div>
                                </div>
                            </React.Fragment>
                        })}

                        {/*{!!questionnaireQ && questionnaireQ!.item.map((i: any, index: number) => {*/}
                        {/*    console.log(i);*/}
                        {/*    return <React.Fragment key={index}>*/}
                        {/*        {index !== 0 && <hr />}*/}
                        {/*        <div className="title is-size-5 mb-1">{index + 1 + '.'}&nbsp;{i.item[0].text}</div>*/}

                        {/*        {i.item[0].answerOption.map((a: any) => {*/}
                        {/*            return <React.Fragment key={a.valueCoding.code}>*/}
                        {/*                <div className="control">*/}

                        {/*                    <input type="radio"*/}
                        {/*                        name={index + "_q_" + a.valueCoding.code}*/}
                        {/*                        className="is-static"*/}
                        {/*                        disabled*/}
                        {/*                        defaultChecked={isChecked(a, i.item[0].linkId, index)} />*/}
                        {/*                    <label className="radio">&nbsp;{a.valueCoding.display}</label>*/}

                        {/*                </div>*/}
                        {/*            </React.Fragment>*/}
                        {/*        })}*/}
                        {/*        /!* <hr /> *!/*/}
                        {/*    </React.Fragment>*/}
                        {/*})}*/}
                    </React.Fragment>}
                </Portlet>
            </div>
        </React.Fragment>
    );
};
export default QuestionnaireAnsweredScreen;