import React, { useContext, useEffect, useState } from "react"
import Portlet from "../../components/portlet/Portlet"
import { Link, useParams } from 'react-router-dom';
import { AuthGet, AuthPost } from "../../services/axios";
import { InternationalizationContext } from "../../context/InternationalizationProvider";

interface QuestionnaireRequest {
    QNID: string
    RID: number
    flag: number
    timestamp: number
    title: string
    score: number
}

const PatientQuestionnairesScreen: React.FC = () => {
    const { patientId } = useParams<{ patientId: string }>();
    const [entries, setEntries] = useState<QuestionnaireRequest[]>();
    const [shownEntries, setShownEntries] = useState<QuestionnaireRequest[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const context = useContext(InternationalizationContext);

    const getPatientsQuestionnaires = React.useCallback(() => {
        setIsLoading(true);
        AuthGet('origin=hcp&destination=cloud&data[resourceType]=Questionnaire&data[identifier]=' + patientId)
            .then(res => {
                console.log(res.data);
                setEntries(res.data);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setIsLoading(false));
    }, [patientId]);

    useEffect(() => {
        getPatientsQuestionnaires();
    }, [getPatientsQuestionnaires]);

    useEffect(() => {
        console.log(context.locale)
        if (!entries) {
            return
        }
        console.log(entries.filter((e)=> e.QNID.includes("_" + context.locale)))
        setShownEntries(entries.filter((e)=> e.QNID.includes("_" + context.locale)))
    }, [entries, context.locale])

    const getStatusColor = (status: number) => {
        if (status === 0) {
            return 'is-warning';
        }
        if (status === 1) {
            return 'is-grey';
        }
        return 'is-danger';
    };

    const getStatus = (status: number) => {
        if (status === 0) {
            return 'Pending';
        }
        if (status === 1) {
            return 'Answered';
        }
        return 'Expired';
    };

    const getNumberOfAnswered = (entries: any) => {
        let i = 0;
        entries.forEach((e: any) => {
            if (e.flag === 1) {
                i++;
            }
        });
        return i;
    }

    const sendMAUQ = () => {
        const data = {
            origin: "hcp",
            destination: "cloud",
            data: {
                resourceType: "Requests",
                resourceData: ["MAUQ-IPA_EN", "MAUQ-IPA_IT", "MAUQ-IPA_DE"],
                Patient: patientId
            },
            type: "sync",
            notifyUrl: ""
        }
        setIsProcessing(true);
        AuthPost("", data)
            .then(res => {
                console.log(res.data);
                getPatientsQuestionnaires()
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setIsProcessing(false));
    }

    return (
        <React.Fragment>
            <Portlet title="Questionnaires" noPadding isLoading={isLoading}
                     actions={<button className={"button is-primary is-outlined" + (isProcessing ? " is-loading" : "")}
                     onClick={sendMAUQ}>Send MAUQ</button>}
                     subtitle={!!shownEntries ? getNumberOfAnswered(shownEntries) + "/" + shownEntries.length : undefined}>
                {(!shownEntries || shownEntries.length === 0) && <div className="notification is-info is-light m-3">
                    There are no questionnaires for the patient!!!
                </div>}

                {!!shownEntries && shownEntries.length > 0 &&
                    <div className="table-wrapper">
                        <table className="table is-fullwidth is-hoverable mb-0">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Questionnaire</th>
                                <th>Status</th>
                                <th>Score</th>
                                <th style={{width: "100px"}}>Date</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {shownEntries.map((entry: QuestionnaireRequest, index: number) => {
                                return <tr key={index}>
                                    <td className="is-vcentered">
                                        <span>{index + 1}</span>
                                    </td>
                                    <td className="is-vcentered">
                                        <span>{entry.QNID}</span>
                                    </td>
                                    <td className="is-vcentered">
                                        <span
                                            className={"tag is-uppercase " + getStatusColor(entry.flag)}>{getStatus(entry.flag)}</span>
                                    </td>
                                    <td className="is-vcentered">
                                        {entry.score}
                                    </td>
                                    <td className="is-vcentered">
                                        <span>{new Date(entry.timestamp * 1000).toLocaleDateString()}</span>
                                    </td>
                                    <td className="is-vcentered">
                                        {entry.RID > 0 ? <div className="buttons has-addons is-right">
                                            <Link
                                                to={"/secure/patients/" + patientId + '/questionnaires/type/' + entry.QNID + '/answer/' + entry.RID}
                                                className="button is-rounded is-outlined is-link">Open</Link>
                                        </div> : <span>&nbsp;</span>}
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>}
            </Portlet>
        </React.Fragment>
    );
};
export default PatientQuestionnairesScreen;