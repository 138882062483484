import { FhirEntry } from "../secure/model/fhir";
import { Constants } from "./constants";

export const setDefinitions = (data: FhirEntry[]) => {
    sessionStorage.setItem(Constants.QUESTIONNAIRE_DEFINITIONS, JSON.stringify(data))
}

export const clearDefinitions = () => {
    sessionStorage.removeItem(Constants.QUESTIONNAIRE_DEFINITIONS)
}

export const getDefinitions = (): FhirEntry[] => {
    const qd = sessionStorage.getItem(Constants.QUESTIONNAIRE_DEFINITIONS)
    if (!!qd) {
        return JSON.parse(qd)
    }
    return []
}

export const definitionsExist = (): boolean => {
    return !!sessionStorage.getItem(Constants.QUESTIONNAIRE_DEFINITIONS)
}