import '../css/style.css';
import { env } from '../env'

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import KeycloakProvider, { configureKeycloak, Login, Logout, PrivateRoute } from 'react-router-keycloak';

import Loader from '../frontend/Loader';
import LandingPage from '../frontend/LandingPage';
import SecureRouter from '../secure/router/SecureRouter';
import InternationalizationProvider from '../context/InternationalizationProvider';
import PatientProvider from '../context/PatientProvider'
import PractitionerProvider from "../context/PractitionerProvider";
import { clearDefinitions } from "../storage/QuestionnaireDefinitions";
import PatientVitalsScreen from "../secure/patients/PatientVitalsScreen";
import ClusteringProvider from "../context/ClusteringProvider";
import { clearStorage } from "../storage/clearStorage";

const KEYCLOAK_URL = env.REACT_APP_KC_URL;
const KEYCLOAK_REALM = env.REACT_APP_KC_REALM;
const KEYCLOAK_CLIENT_ID = env.REACT_APP_KC_CLIENT_ID;

console.log(env.REACT_APP_KC_URL)
console.log(env)

function handleRefresh(token: any) {
    console.log('Called every time the token is refreshed so you can update it locally', token);
}

configureKeycloak(KEYCLOAK_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID);

const AppRouter: React.FC = () => {
    return (
        <InternationalizationProvider>
            <KeycloakProvider loginPath="/login" logoutPath="/logout" onRefresh={handleRefresh} refreshRate={3600}>
                <PractitionerProvider>
                    <ClusteringProvider>
                        <PatientProvider>
                            <BrowserRouter>
                                <Switch>
                                    <Route path="/login" render={props => <Login redirectTo="/secure" {...props}>
                                        <Loader/>
                                    </Login>}/>
                                    <Route path="/logout" render={props => {
                                        clearStorage()
                                        return <Logout redirectTo="/" {...props} />
                                    }}/>

                                    <PrivateRoute path="/secure" component={SecureRouter}/>
                                    <Route path="/" component={LandingPage}/>
                                </Switch>
                            </BrowserRouter>
                        </PatientProvider>
                    </ClusteringProvider>
                </PractitionerProvider>
            </KeycloakProvider>
        </InternationalizationProvider>
    )
};
export default AppRouter;