import { TimeSeries } from 'pondjs';
import React, { useEffect, useState } from 'react';
import { ChartContainer, ChartRow, Charts, LineChart, Resizable, styler, YAxis } from "react-timeseries-charts";
import { augmentPeriod, Period } from './utils';

export type ChartType = "series" | "line" | "bar"

interface HRMeasurement {
    timestamp: number
    min: number
    max: number
    mean: number
}

const style = styler([
    {key: "min", color: "steelblue", width: 2},
    {key: "max", color: "steelblue", width: 2},
    {key: "mean", color: "#F68B24", width: 2}
]);

const CrossHairs = (props: any) => {
    const {x, y} = props;
    const style = {stroke: "#ccc"}; // pointerEvents: "none",
    if (!!x && !!y) {
        return <g>
            <line style={style} x1={0} y1={y} x2={props.width} y2={y}/>
            <line style={style} x1={x} y1={0} x2={x} y2={props.height}/>
        </g>
    } else {
        return <g/>;
    }
}

const calculateDaysPrior = (p: Period) => {
    switch (p) {
        case "week":
            return 7;
        case "month":
            return 30;
        case "6months":
            return 182;
        default:
            return 365;
    }
}

interface Props {
    measurements: HRMeasurement[]
}

const HRDataChart: React.FC<Props> = ({measurements}) => {
    const title = "Heart Rate"

    const [period, setPeriod] = useState<Period>("week");
    const [state, setState] = useState({
        tracker: null,
        x: null, y: null
    })

    function buildPoints(m: HRMeasurement[], p: Period) {
        const prior = calculateDaysPrior(p)
        const beginDate = new Date()
        beginDate.setDate(new Date().getDate() - prior)
        const measurements: any[] = []
        m.forEach(mm => {
            if (new Date(mm.timestamp) > beginDate) measurements.push([new Date(mm.timestamp).getTime(), mm.min, mm.max, mm.mean.toFixed(2)])
        })
        if (measurements.length === 0) {
            setPeriod(augmentPeriod(period))
        }
        return measurements
    }

    const createTimeSeries = (p: Period) => {
        return new TimeSeries({
            name: "HR",
            columns: ["time", "min", "max", "mean"],
            points: buildPoints(measurements, p)
        })
    }

    const [hrSeries, setHrSeries] = useState<any>(createTimeSeries(period))

    useEffect(() => {
        setHrSeries(createTimeSeries(period))
    }, [period])

    const handleMouseMove = (x: any, y: any) => {
        setState({...state, x, y});
    };

    const handleTrackerChanged = (tracker: any) => {
        if (!tracker) {
            setState({...state, tracker, x: null, y: null});
        } else {
            setState({...state, tracker});
        }
    };

    const isActive = (p: Period) => {
        return p === period;
    };

    return (
        <React.Fragment>
            <div>
                <p className="title is-5">{title}</p>
            </div>
            <div className="columns is-centered is-vcentered">
                <div className="column is-left">

                </div>
                <div className="column is-right has-text-right is-narrow">
                    <div className="tabs mb-0">
                        <ul id={title}>
                            <li className={isActive("week") ? "is-active" : ""}>
                                <a href={"#" + title} onClick={() => setPeriod("week")}>7 days</a>
                            </li>
                            <li className={isActive("month") ? "is-active" : ""}>
                                <a href={"#" + title} onClick={() => setPeriod("month")}>4 weeks</a>
                            </li>
                            <li className={isActive("6months") ? "is-active" : ""}>
                                <a href={"#" + title} onClick={() => setPeriod("6months")}>6 months</a>
                            </li>
                            <li className={isActive("year") ? "is-active" : ""}>
                                <a href={"#" + title} onClick={() => setPeriod("year")}>Year</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>

                {!!hrSeries.range() &&
                    <React.Fragment>
                        <Resizable>
                            <ChartContainer
                                // title={title}
                                // titleStyle={{fill: "#555", fontWeight: 500}}
                                timeRange={hrSeries.range()}
                                format="%d/%m"
                                timeAxisTickCount={hrSeries.size()}
                                // timeAxisAngledLabels
                                maxTime={hrSeries.range().end()}
                                minTime={hrSeries.range().begin()}

                                onTrackerChanged={handleTrackerChanged}
                                onMouseMove={(x: any, y: any) => handleMouseMove(x, y)}
                                // showGrid
                            >
                                <ChartRow height="200">
                                    <YAxis
                                        id="y"
                                        label={"HR"}
                                        width="60"
                                        min={hrSeries.min("min", (v: any) => v) - 5}
                                        max={hrSeries.max("max") + 5}
                                        type="linear"
                                        hideAxisLine
                                        format={",.0f"}/>
                                    <Charts>
                                        <LineChart axis="y"
                                                   series={hrSeries}
                                                   style={style}
                                                   columns={["min", "max", "mean"]}
                                        />
                                        <CrossHairs x={state.x} y={state.y}/>
                                    </Charts>
                                </ChartRow>
                            </ChartContainer>
                        </Resizable>

                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
};
export default HRDataChart;