import React from "react";
import { Link } from "react-router-dom";

interface Props {
    to?: string
    className?: string
}

const BreadcrumbItem: React.FC<Props> = ({ to, className = '' , children}) => {
    return (<React.Fragment>
        <li className={(!to ? ' is-active ' : ' ') + className}>
            {!to && <span className="ml-2" aria-current="page">{children}</span>}
            {!!to && <Link to={to}>
                <span>{children}</span>
            </Link>}
        </li>
    </React.Fragment>)
};
export default BreadcrumbItem;