export type Period = "week" | "month" | "6months" | "year";

export const augmentPeriod = (period: Period): Period => {
    switch (period) {
        case "week":
            return "month"
        case "month":
            return "6months"
        case "6months":
            return "year"
        default:
            return "week"
    }
}