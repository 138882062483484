declare global {
    interface Window {
        env: any
    }
}
type EnvType = {
    REACT_APP_KC_URL: string,
    REACT_APP_KC_REALM: string,
    REACT_APP_KC_CLIENT_ID: string,
    REACT_APP_ORCHESTRATOR_URL: string
    REACT_APP_DATA_AGGR_URL: string
}
export const env: EnvType = { ...process.env, ...window.env }