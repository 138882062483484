import { CancerType } from "../../model/cancer";

export type Condition =
    | "ANXIETY"
    | "DEPRESSION"
    | "FATIGUE"
    | "HAIR LOSS"
    | "LACK OF SEXUAL INTEREST"
    | "SLEEP PROBLEMS"
    | "SORE MOUTH"
    | "WEIGHT LOSS"
    | "APPETITE LOSS" // crc
    | "COLITIS" // crc
    | "HAND-FOOT SYNDROME" // crc
    | "PERIPHERAL NEUROPATHY" // crc
    | "WEIGHT CHANGES"; // crc

export interface Interventions {
    aml?: InterventionLanguages;
    crc?: InterventionLanguages;
}

export interface InterventionLanguages {
    en?: InterventionCondition;
    de?: InterventionCondition;
    it?: InterventionCondition;
}

export const EmptyInterventionCondition: InterventionCondition = {
    ANXIETY: [],
    DEPRESSION: [],
    FATIGUE: [],
    LACK_OF_SEXUAL_INTEREST: [],
    SLEEP_PROBLEMS: [],
    SORE_MOUTH: [],
    WEIGHT_LOSS: [],
    APPETITE_LOSS: [],
    COLITIS: [],
    HAND_FOOT_SYNDROME: [],
    PERIPHERAL_NEUROPATHY: [],
    WEIGHT_CHANGES: []
}

export const ConditionsMap: { [short: string]: ConditionDetails } = {
    FATIGUE: {
        name: "FATIGUE",
        short: "FAT",
        key: "FATIGUE",
        label: "Fatigue"
    },
    ANXIETY: {
        name: "ANXIETY",
        short: "ANX",
        key: "FATIGUE",
        label: "Anxiety"
    },
    DEPRESSION: {
        name: "DEPRESSION",
        short: "DEP",
        key: "DEPRESSION",
        label: "Depression"
    },
    LACK_OF_SEXUAL_INTEREST: {
        name: "LACK OF SEXUAL INTEREST",
        short: "LSI",
        key: "LACK_OF_SEXUAL_INTEREST",
        label: "Lack of sexual interest"
    },
    HAIR_LOSS: {
        name: "HAIR LOSS",
        short: "HR",
        key: "HAIR_LOSS",
        label: "Hair loss"
    },
    SLEEP_PROBLEMS: {
        name: "SLEEP PROBLEMS",
        short: "SP",
        key: "SLEEP_PROBLEMS",
        label: "Sleep problems"
    },
    SORE_MOUTH: {
        name: "SORE MOUTH",
        short: "SM",
        key: "SORE_MOUTH",
        label: "Sore mouth"
    },
    WEIGHT_LOSS: {
        name: "WEIGHT LOSS",
        short: "WL",
        key: "WEIGHT_LOSS",
        label: "Weight loss"
    },
    APPETITE_LOSS: {
        name: "APPETITE LOSS",
        short: "AL",
        key: "APPETITE_LOSS",
        label: "Appetite loss"
    },
    COLITIS: {
        name: "COLITIS",
        short: "COL",
        key: "COLITIS",
        label: "Colitis"
    },
    HAND_FOOT_SYNDROME: {
        name: "HAND-FOOT SYNDROME",
        short: "HFS",
        key: "HAND_FOOT_SYNDROME",
        label: "Hand-foot syndrome"
    },
    PERIPHERAL_NEUROPATHY: {
        name: "PERIPHERAL NEUROPATHY",
        short: "PN",
        key: "PERIPHERAL_NEUROPATHY",
        label: "Peripheral neuropathy"
    },
    WEIGHT_CHANGES: {
        name: "WEIGHT CHANGES",
        short: "WC",
        key: "WEIGHT_CHANGES",
        label: "Weight changes"
    }
}

export interface ConditionDetails {
    name: Condition,
    short: string
    key: string
    label: string
}

export interface InterventionCondition {
    ANXIETY: Intervention[];
    DEPRESSION: Intervention[];
    FATIGUE: Intervention[];
    LACK_OF_SEXUAL_INTEREST: Intervention[]
    SLEEP_PROBLEMS: Intervention[]
    SORE_MOUTH: Intervention[]
    WEIGHT_LOSS: Intervention[]
    APPETITE_LOSS: Intervention[]
    COLITIS: Intervention[]
    HAND_FOOT_SYNDROME: Intervention[]
    PERIPHERAL_NEUROPATHY: Intervention[]
    WEIGHT_CHANGES: Intervention[]
}

export interface Intervention {
    id?: string;
    cancerType?: CancerType;
    name: string;
    condition: Condition;
    options?: string[];
}

export interface InterventionData {
    name: string;
    code: string;
    options?: string[];
    cancerType: CancerType[];
}

export interface InterventionResponse {
    INTID: string
    Title: string
    Options: string
    Cancer: string
    Condition: string
    Feedback: string
    Timestamp: number
}
