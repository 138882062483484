import React, { useState } from 'react';
import { Constants } from "../storage/constants";

export type Language = "EN" | "DE" | "IT";

interface Internationalization {
    locale: Language,
    selectLanguage: (lang: Language) => void
}
export const InternationalizationContext = React.createContext<Internationalization>({ locale: "EN", selectLanguage: (lang: Language) => { } });

const getLocalStorageLangOrDefault = (): Language => {
    const local = localStorage.getItem(Constants.LANGUAGE)
    if (!!local && local !== "null") {
        return local as Language
    }
    return "EN"
}

const local: Language = getLocalStorageLangOrDefault()

const InternationalizationProvider = (props: any) => {
    const [locale, setLocale] = useState<Language>(local);
    localStorage.setItem(Constants.LANGUAGE, locale)

    function selectLanguage(lang: Language) {
        console.log("New lang: " + lang);
        setLocale(lang);
        localStorage.setItem(Constants.LANGUAGE, lang)
    }

    return (
        <InternationalizationContext.Provider value={{ locale, selectLanguage }}>
            {props.children}
        </InternationalizationContext.Provider>
    );
}

export default InternationalizationProvider;