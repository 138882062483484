import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import BreadcrumbItem from "../../components/breadcrumb/BreadcrumbItem";
import React, { useEffect, useState } from "react";
import Portlet from "../../components/portlet/Portlet";
import { AuthGet } from "../../services/axios";
import { AxiosResponse } from "axios";

interface Notification {
    ID: string
    type: string
    user: string
    timestamp: number
    flag: number
    data: any
}

const NotificationsScreen: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<Notification[]>()

    const loadPatients = () => {
        setIsLoading(true)
        AuthGet('origin=hcp&destination=cloud&data[resourceType]=Notifications&type=sync&notifyUrl')
            .then((res: AxiosResponse) => {
                console.log(res.data)
                setNotifications(res.data)
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(loadPatients, [])

    return (<>
        <div className="mb-4">
            <Breadcrumb>
                <BreadcrumbItem to="/secure/notifications">Dashboard</BreadcrumbItem>
                <BreadcrumbItem>Notifications</BreadcrumbItem>
            </Breadcrumb>
        </div>

        <div className="my-4">
            <Portlet title="Notifications"
                     subtitle={notifications?.length + ""}
                     isLoading={isLoading}
                     noPadding>
                <div className="table-wrapper">
                    <table className="table is-fullwidth is-hoverable mb-0">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Type</th>
                            <th>Patient</th>
                            <th>Data</th>
                            <th>Timestamp</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!!notifications && notifications.map((p: Notification) => {
                            return <tr key={p.ID}>
                                <td className="is-vcentered">
                                    <span>{p.ID}</span>
                                </td>
                                <td className="is-vcentered">
                                    <span>{p.type}</span>
                                </td>
                                <td className="is-vcentered">
                                    <span>{p.user}</span>
                                </td>
                                <td className="is-vcentered">
                                    <span>{p.data}</span>
                                </td>
                                <td className="is-vcentered">
                                    <span>{new Date(p.timestamp * 1000).toDateString()}</span>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>

                </div>

            </Portlet>


        </div>
    </>)
}
export default NotificationsScreen