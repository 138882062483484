export type Gender = "male" | "female"
export type ResourceType = "ActivityDefinition"
    | "Patient"
    | "Questionnaire"
    | "QuestionnaireResponse"
    | "Observation"
export type FhirLanguage = "en" | "de" | "it"
export type FhirRelation = "self" | "next"
export type FhirInterventionGroup = "GROUP_AML" | "GROUP_CRC"
export type FhirItemType = "group" | "choice"
export type FhirStatus = "final" | "generated"
export type FhirNameUse = "official" | "nickname"

export const IdentifierSystem: string = "urn:ietf:rfc:3986"
export const CancerSystem: string = "COHORT"
export const InterventionGroupSystem: string = "INTERVENTION_GROUP"
export const PatientNeedIdentifier: string = "PATIENT_NEED"

export interface FhirResponse {
    resourceType: "Bundle",
    id: string
    meta: FhirMeta
    type: string
    total: number
    link: {
        relation: FhirRelation
        url: string
    }[]
    entry: FhirEntry[]
}

export interface FhirEntry {
    fullUrl: string
    resource: FhirEntryResource
    search: FhirSearch
}

export interface FhirEntryResource {
    resourceType: ResourceType
    id: string
    meta: FhirMeta
    identifier: FhirIdentifier[]
    text?: {
        status: FhirStatus
        div: string
    }

    language?: FhirLanguage
    title?: string

    name?: string | FhirName[]

    // activity definitions
    publisher?: string
    description?: string
    intent?: "proposal"
    priority?: "routine"

    // patient
    active?: boolean
    gender?: Gender
    deceasedBoolean?: boolean
    generalPractitioner?: FhirReference[]
    managingOrganization?: FhirReference
    search?: FhirSearch

    // questionnaire
    item: FhirItem[]
    questionnaire: string
    subject: FhirReference

    // practitioner
    telecom: FhirIdentifier[]
    qualification: FhirQualification[]

}

export interface FhirQualification {
    code: {
        text: string
    }
    issuer: FhirReference
}

export interface FhirName {
    use: FhirNameUse
    text?: string
    given?: string[]
}

export interface FhirItem {
    linkId: string
    text: string
    type?: FhirItemType

    required?: boolean
    answerOption?: FhirItemAnswerOption[]

    answer: FhirItemAnswerOption[]

    item?: FhirItem[]

    maxLength: number
}

export interface FhirItemAnswerOption {
    valueCoding: {
        code: string
        display?: string
    }
}

export interface FhirSearch {
    mode: "match"
}

export interface FhirMeta {
    version?: string
    lastUpdated: string
    source?: string
}

export interface FhirIdentifier {
    system: string
    value: string
}

export interface FhirReference {
    reference: string
}