import React, { useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import BreadcrumbItem from "../../components/breadcrumb/BreadcrumbItem";
import Portlet from "../../components/portlet/Portlet";
import { useForm } from "react-hook-form";
import { AuthPut } from "../../services/axios";

const ChangePassword: React.FC = () => {
    const form = useForm()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [processing, setProcessing] = useState<boolean>(false)

    const onSubmit = (data: any) => {
        if (data.new !== data.confirm) {
            setErrorMessage("New password must be the same as the Confirm password!")
            return
        }

        setProcessing(true)
        const payload = {
            origin: "hcp",
            data: {
                password: {
                    old: data.old,
                    new: data.new
                },
            },
            type: "sync",
            notifyUrl: ""
        }
        console.log(payload)

        AuthPut("/user", payload)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => setProcessing(false))
    }

    return (
        <React.Fragment>
            <div className="mb-4">
                <Breadcrumb>
                    <BreadcrumbItem to="/secure/dashboard">Dashboard</BreadcrumbItem>
                    <BreadcrumbItem>Change Password</BreadcrumbItem>
                </Breadcrumb>
            </div>

            <div className="my-4">
                <Portlet title="Change Password">
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="columns is-multiline">
                            <div className="column is-one-third"/>
                            <div className="column is-one-third">
                                <div className="field">
                                    <label className="label">Current Password</label>
                                    <div className="control">
                                        <input {...form.register("old")} className="input" type="password"
                                               name="old"/>
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">New Password</label>
                                    <div className="control">
                                        <input {...form.register("new")}
                                               onChange={() => setErrorMessage(undefined)}
                                               className={"input" + (!!errorMessage ? " is-danger" : "")}
                                               type="password"
                                               name="new"/>
                                    </div>
                                </div>
                                {!!errorMessage && <p className="has-text-danger mb-3">{errorMessage}</p>}

                                <div className="field">
                                    <label className="label">Confirm Password</label>
                                    <div className="control">
                                        <input {...form.register("confirm")}
                                               onChange={() => setErrorMessage(undefined)}
                                               className={"input" + (!!errorMessage ? " is-danger" : "")}
                                               type="password"
                                               name="confirm"/>
                                    </div>
                                </div>
                                {!!errorMessage && <p className="has-text-danger mb-3">{errorMessage}</p>}

                                <div className="columns">
                                    <div className="column is-one-third"/>
                                    <div className="column is-one-third">
                                        <div className="buttons">
                                            <button type="submit"
                                                    className={"button is-primary" + (processing ? " is-loading": "")}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="column is-one-third"/>
                                </div>
                            </div>
                            <div className="column is-one-third"/>
                        </div>

                    </form>
                </Portlet>
            </div>
        </React.Fragment>
    )
}
export default ChangePassword