import { faSignal, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import FlagIcon from '../../helpers/Flags';
import logo from '../../img/logomark_200x200.png';
import { Language } from "../../context/InternationalizationProvider";

interface Props {
    action?: string
    locale: Language
    setLanguage: (e: any) => void
}

const LayoutHeader: React.FC<Props> = ({ action = "LOGOUT", locale, setLanguage }) => {
    const [currentLanguage, setCurrentLanguage] = useState<Language>(locale);

    const setNewLanguage = (lang: Language) => {
        setLanguage(lang);
        setCurrentLanguage(lang);
    };
    const isSelected = (lang: Language) => {
        return lang === currentLanguage;
    };

    return (
        <React.Fragment>
            <header className="hero is-white">
                <div className="hero-head">
                    <nav className="navbar is-white" role="navigation" aria-label="main navigation">
                        <div className="container" style={{ maxWidth: "100%" }}>
                            <div className="navbar-brand" style={{ marginLeft: ".75rem" }}>
                                <Link className="navbar-item" to="/secure/dashboard" title="WELMO">
                                    <div className="columns is-mobile is-vcentered">
                                        <div className="column is-narrow is-paddingless mx-2" style={{ width: "40px" }}>
                                            <img src={logo} className="logo" alt="WELMO logo" style={{ maxHeight: "40px" }} />
                                        </div>
                                        <div className="column is-paddingless has-text-primary">
                                            <h1 className="title mb-0">ONCORELIEF</h1>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="navbar-menu">
                                <div className="navbar-end pr-3">
                                    {(action === "LOGOUT" || action === "DASHBOARD") && <div className="navbar-item has-text-primary">
                                        <a className={"icon" + (!isSelected("EN") ? ' is-small ' : '')} onClick={() => setNewLanguage("EN")}>
                                            <FlagIcon code="gb-eng" size={'lg'} />
                                        </a>&nbsp;
                                        <a className={"icon" + (!isSelected("DE") ? ' is-small ' : '')} onClick={() => setNewLanguage("DE")}>
                                            <FlagIcon code="de" size={'lg'} />
                                        </a>&nbsp;
                                        <a className={"icon" + (!isSelected("IT") ? ' is-small ' : '')} onClick={() => setNewLanguage("IT")}>
                                            <FlagIcon code="it" size={'lg'} />
                                        </a>&nbsp;
                                    </div>}
                                    {action === "LOGOUT" &&
                                        <Link to="/logout" className="navbar-item has-text-primary">
                                            <span className="icon"><FontAwesomeIcon icon={faSignOutAlt} /></span>
                                            <span>Sign out</span>
                                        </Link>
                                    }
                                    {action === "LOGIN" &&
                                        <Link to="/login" className="navbar-item has-text-primary">
                                            <span className="icon"><FontAwesomeIcon icon={faSignInAlt} /></span>
                                            <span>Sign in</span>
                                        </Link>
                                    }
                                    {action === "DASHBOARD" &&
                                        <Link to="/secure/dashboard" className="navbar-item has-text-primary">
                                            <span className="icon"><FontAwesomeIcon icon={faSignal} /></span>
                                            <span>Sign out</span>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

            </header>
        </React.Fragment>
    );
};
export default LayoutHeader;