import React from 'react';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../components/breadcrumb/BreadcrumbItem';

const DashboardScreen: React.FC = () => {
    return (
        <React.Fragment>
            <div className="mb-4">
                <Breadcrumb>
                    <BreadcrumbItem>Dashboard</BreadcrumbItem>
                </Breadcrumb>
            </div>

            <div className="mb-4">
                <div className="columns is-centered">
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-content" style={{ minHeight: "275px" }}>
                                <div className="media">
                                    <div className="media-content">
                                        <p className="title is-4 has-text-primary">Patients</p>
                                    </div>
                                </div>

                                <div className="content">
                                    <p className="has-text-primary">Monitor patient's health status provided by the sensing framework and
                                        the AI engine of the ONCORELIEF project.
                                        Review questionnaires' status and answers.
                                        Propose suggestions.</p>
                                </div>
                            </div>
                            <div className="card-footer">
                                <Link to="/secure/patients" className="card-footer-item button is-text is-pulled-right has-text-primary">Go to patients</Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className="column">
                        <div className="card">
                            <div className="card-content" style={{ minHeight: "275px" }}>
                                <div className="media">
                                    <div className="media-content">
                                        <p className="title is-4 has-text-primary">Questionnaires</p>
                                    </div>
                                </div>

                                <div className="content">
                                    <p className="has-text-primary">Review patients' answers to questionnaires.</p>
                                </div>
                            </div>
                            <div className="card-footer">
                                <Link to="/secure/questionnaires" className="card-footer-item button is-text is-pulled-right has-text-primary">Go to Questionaires</Link>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <div className="card-content" style={{ minHeight: "275px" }}>
                                <div className="media">
                                    <div className="media-content">
                                        <p className="title is-4 has-text-primary">Guidance</p>
                                    </div>
                                </div>

                                <div className="content">
                                    <p className="has-text-primary">Response to Requests for Guidance initiated by patients.</p>
                                </div>
                            </div>
                            <div className="card-footer">
                                <Link to="/secure/guidance" className="card-footer-item button is-text is-pulled-right has-text-primary">Go to Guidance</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </React.Fragment>
    );
};
export default DashboardScreen;