import React, { useEffect, useState } from 'react';

import Portlet from '../../components/portlet/Portlet';
import SimpleDataChart from "../../components/charts/HRDataChart";
import StepsDataChart from "../../components/charts/StepsDataChart";
import StressDataChart from "../../components/charts/StressDataChart";
import { AuthDataAggrGet } from "../../services/axios";
import { useParams } from "react-router-dom";

// todo: refactor charts
const PatientVitalsScreen: React.FC = () => {
    const {patientId} = useParams<{ patientId: string }>();

    const [stress, setStress] = useState<any>();
    const [steps, setSteps] = useState<any>();
    const [heartRate, setHeartRate] = useState<any>();

    const formatDate = (d: Date): string => {
        return [formatNumber(d.getDate()),
                formatNumber(d.getMonth() + 1),
                d.getFullYear()].join('-') + 'T' +
            [formatNumber(d.getHours()),
                formatNumber(d.getMinutes()),
                formatNumber(d.getSeconds())].join(':');
    }

    const formatNumber = (n: number) => {
        if (n.toString().length === 1) {
            return "0" + n.toString();
        }
        return n.toString()
    }

    const sorting = (p1: any, p2: any): number => {
        return (p1.timestamp < p2.timestamp) ? -1 : (p1.timestamp > p2.timestamp) ? 1 : 0
    }

    const getVitals = () => {
        // let patientId = 'OR1339'
        let to = new Date()
        let from = new Date(to)
        from.setDate(from.getDate() - 365);
        AuthDataAggrGet(`/api/patient/${patientId}/stress?from=${formatDate(from)}&to=${formatDate(to)}&period=1d`)
            .then(res => {
                const data = res.data.sort(sorting)
                setStress(data)
            })

        AuthDataAggrGet(`/api/patient/${patientId}/steps?from=${formatDate(from)}&to=${formatDate(to)}&period=1d`)
            .then(res => {
                const data = res.data.sort(sorting)
                setSteps(data)
            })

        AuthDataAggrGet(`/api/patient/${patientId}/heart_rate?from=${formatDate(from)}&to=${formatDate(to)}&period=1d`)
            .then(res => {
                const data = res.data.sort(sorting)
                setHeartRate(data)
            })
    }

    useEffect(getVitals, [])


    return (
        <React.Fragment>
            <Portlet title="Vitals">
                {!!heartRate ? <SimpleDataChart measurements={heartRate}/> :
                    <div className="notification is-warning is-light">No heart rate data found for patient.</div>}
                <hr/>
                {!!steps ? <StepsDataChart measurements={steps}/> :
                    <div className="notification is-warning is-light">No steps data found for patient.</div>}
                <hr/>

                {!!stress ? <StressDataChart measurements={stress}/> :
                    <div className="notification is-warning is-light">No stress data found for patient.</div>}
            </Portlet>
        </React.Fragment>
    );
};
export default PatientVitalsScreen;