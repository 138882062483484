import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import keycloak from 'react-router-keycloak';
import logo from '../img/logomark_200x200.png';
import LayoutFooter from '../components/layout/LayoutFooter';
import LayoutHeader from '../components/layout/LayoutHeader';
import { InternationalizationContext } from '../context/InternationalizationProvider';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LandingPage: React.FC = () => {
    console.log(keycloak.isAuthenticated)
    const action = !!keycloak.isAuthenticated && keycloak.isAuthenticated ? "DASHBOARD" : "LOGIN";
    const context = useContext(InternationalizationContext);

    return (
        <React.Fragment>
            <LayoutHeader action={action} locale={context.locale} setLanguage={context.selectLanguage}/>

            <main>
                <div className="is-vcentered is-centered has-background-grey-lighter sidebar py-6">
                    <div className="columns">
                        <div className="column is-one-fifth is-hidden-touch"/>
                        <div className="column is-centered has-text-centered">
                            <div className="box">
                                <div className="mb-6">
                                    <h1 className="title is-5">Welcome to ONCORELIEF</h1>
                                    <p className="subtitle"> Health-Care Professional platform</p>
                                </div>

                                <div className="my-6">
                                    <img src={logo} className="App-logo" alt="logo"/>
                                </div>

                                <div className="buttons is-centered my-3">
                                    <Link to="/login" className="button is-primary is-outlined">
                                        <span><FontAwesomeIcon icon={faSignInAlt}/></span>&nbsp;
                                        <span>Sign in</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="column is-one-fifth is-hidden-touch"/>
                    </div>
                </div>
            </main>

            <LayoutFooter/>
        </React.Fragment>
    );
};
export default LandingPage;