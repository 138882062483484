import React, { useState } from 'react';
import { Constants } from "../storage/constants";
import { FhirEntryResource, FhirReference } from "../secure/model/fhir";

interface Practitioner {
    practitioner?: FhirEntryResource,
    selectPractitioner: (practitioner: FhirEntryResource) => void
    isPatientOf: (practitionerList: FhirReference[]) => boolean
}

export const PractitionerContext = React.createContext<Practitioner>({
    selectPractitioner: () => {},
    isPatientOf: (practitionerList?: FhirReference[]) => false
});

const getPractitioner = (): FhirEntryResource | undefined => {
    const p = sessionStorage.getItem(Constants.PRACTITIONER)
    return !!p ? JSON.parse(p) : undefined
}

const sessionPractitioner = getPractitioner();

const PractitionerProvider = (props: any) => {
    const [practitioner, setPractitioner] = useState<FhirEntryResource | undefined>(sessionPractitioner);

    function selectPractitioner(practitioner: FhirEntryResource) {
        console.log("New practitioner: " + JSON.stringify(practitioner));
        sessionStorage.setItem(Constants.PRACTITIONER, JSON.stringify(practitioner))
        setPractitioner(practitioner);
    }

    const getPractitionerId = () => {
        if (!practitioner) {
            return undefined
        }
        return practitioner.id
    }

    const isPatientOf = (practitionerList?: FhirReference[]) => {
        if (!practitionerList) {
            return false
        }
        const foundPractitioner = practitionerList.find((p: FhirReference) => p.reference === "Practitioner/" + getPractitionerId())
        return !!foundPractitioner
    }

    return (
        <PractitionerContext.Provider value={{practitioner, selectPractitioner, isPatientOf}}>
            {props.children}
        </PractitionerContext.Provider>
    );
}

export default PractitionerProvider;