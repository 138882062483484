import React from "react";

interface Props {
    className?: string
}

const Breadcrumb: React.FC<Props> = ({children, className = ''}) => {
    return (<nav className={'breadcrumb  has-succeeds-separator ' + className} aria-label="breadcrumbs">
        <ul>
            {children}
        </ul>
    </nav>)
};
export default Breadcrumb;