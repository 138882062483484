import React, { useContext, useRef } from 'react';

import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../components/breadcrumb/BreadcrumbItem';
import Portlet from '../../components/portlet/Portlet';
import PagingWrapper, { FilterFormHandler } from "../../components/paging/PagingWrapper";
import Paging from "../../components/paging/Paging";
import usePaging from "../../components/paging/paging-hook";
import { PagingRequest } from "../../components/paging/model";
import { PatientTransformer } from "./transformers/patient-list-transformer";
import { PatientItem } from "../model/patient";
import { PatientContext } from "../../context/PatientProvider";
import { useHistory } from "react-router";
import { PractitionerContext } from "../../context/PractitionerProvider";
import { AuthGet } from "../../services/axios";

const PatientsScreen: React.FC = () => {
    const history = useHistory();

    const practitionerContext = useContext(PractitionerContext);

    const loadPatients = (request: PagingRequest) => {
        return AuthGet('origin=hcp&destination=fhir&data[resourceType]=Patient&data[_count]=' + request.size + '&data[_pagesoffset]=' + request.page*request.size)
            .then((res: any) => {
                console.log(res.data)
                return res.data
            })
    }

    const paging = usePaging<any>(loadPatients, PatientTransformer, 10, 0);

    const filterFormsWrapper = useRef<FilterFormHandler>();

    const patientContext = useContext(PatientContext);

    const navigateToPatient = (p: PatientItem) => {
        patientContext.selectPatient(p);
        history.push("/secure/patients/" + p.id);
    }

    return (
        <React.Fragment>
            <div className="mb-4">
                <Breadcrumb>
                    <BreadcrumbItem to="/secure/dashboard">Dashboard</BreadcrumbItem>
                    <BreadcrumbItem>Patients</BreadcrumbItem>
                </Breadcrumb>
            </div>

            <div className="my-4">
                <Portlet title="Patients"
                         subtitle={paging!.totalItems + ""}
                         isLoading={paging!.isLoading}
                         noPadding>

                    <div className="table-wrapper">
                        <PagingWrapper paging={paging} ref={filterFormsWrapper}>
                            <table className="table is-fullwidth is-hoverable mb-0">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Practitioner</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {/* todo: add filter for patients */}
                                {/*<tr className="filters has-background-white-bis">*/}
                                {/*    <td className="smaller">*/}
                                {/*        <div className="control has-icons-left is-expanded">*/}
                                {/*            <input {...form.register("name")}*/}
                                {/*                   name="name"*/}
                                {/*                   className="input is-rounded filter-inputs"*/}
                                {/*                   type="text"*/}
                                {/*                   placeholder="Search"/>*/}
                                {/*            <span className="icon is-left"><FontAwesomeIcon icon={faSearch}/></span>*/}
                                {/*        </div>*/}
                                {/*    </td>*/}
                                {/*    <td>&nbsp;</td>*/}
                                {/*    <td className="smaller has-text-right">*/}
                                {/*        <button onClick={() => paging!.reset(0)} className="button is-rounded"*/}
                                {/*                type="button"*/}
                                {/*                title="Reset filters">*/}
                                {/*            <span className="icon is-left"><FontAwesomeIcon icon={faUndo}/></span>*/}
                                {/*            <span>Reset</span>*/}
                                {/*        </button>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {!!paging && !!paging.response && paging.response.items.map((p: PatientItem) => {
                                    return <tr key={p.id}>
                                        <td className="is-vcentered">
                                            <span>{p.id}</span>
                                        </td>
                                        <td className="is-vcentered">
                                            {practitionerContext.isPatientOf(p.generalPractitioner!) ? <span>OWN</span> : <span>Other</span>}
                                        </td>
                                        <td className="is-vcentered">
                                            <div className="buttons has-addons is-right">
                                                <button type="button" onClick={() => navigateToPatient(p)}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        className="button is-rounded is-outlined is-link">Open
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </PagingWrapper>
                    </div>

                    <Paging {...paging!} />
                    {/*<nav className="m-3 pagination is-centered" role="navigation" aria-label="pagination">*/}
                    {/*    <button onClick={onClickPrevious} disabled={isPreviousDisabled()} className="pagination-previous">Previous</button>*/}
                    {/*    <button onClick={onClickForward} disabled={isForwardDisabled()} className="pagination-next">Next page</button>*/}
                    {/*</nav>*/}
                </Portlet>
            </div>
        </React.Fragment>
    );
};
export default PatientsScreen;