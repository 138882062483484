import React from 'react';
import { Route, Switch } from 'react-router';
import { Link, Redirect, useLocation, useParams } from 'react-router-dom';

import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../components/breadcrumb/BreadcrumbItem';
import PatientQuestionnairesScreen from './PatientQuestionnairesScreen';
import PatientInterventionsScreen from './PatientInterventionsScreen';
import PatientVitalsScreen from './PatientVitalsScreen';
import PatientDetailsBlock from "./components/PatientDetailsBlock";

type TAB_NAMES = "DETAILS" | "QUESTIONNAIRES" | "GUIDANCE" | "SUGGESTIONS" | "VITALS";

const PatientScreen: React.FC = () => {
    const location = useLocation();
    const {patientId} = useParams<{ patientId: string }>();

    const isActive = (tabName: TAB_NAMES) => {
        const pathVar = location.pathname.split('/');
        return tabName === pathVar[pathVar.length - 1].toUpperCase();
    };


    return (
        <React.Fragment>
            <div className="mb-4">
                <Breadcrumb>
                    <BreadcrumbItem to="/secure/dashboard">Dashboard</BreadcrumbItem>
                    <BreadcrumbItem to="/secure/patients">Patients</BreadcrumbItem>
                    <BreadcrumbItem>Patient</BreadcrumbItem>
                </Breadcrumb>
            </div>

            {/*<div className="mb-4 mx-6">*/}
            {/*    <Stats tiles={[{title: "QoL", value: "0.6"}, {title: "QoL", subtitle: "Predicted", value: "0.7"}]}/>*/}
            {/*</div>*/}

            <div className={"mb-4"}>
                <PatientDetailsBlock />
            </div>

            <div className="mb-4">
                <div className="tabs mb-0">
                    <ul>
                        {/*<li className={isActive("DETAILS") ? "is-active" : ""}>*/}
                        {/*    <Link to={"/secure/patients/" + patientId + "/details"}>Details</Link>*/}
                        {/*</li>*/}
                        <li className={isActive("VITALS") ? "is-active" : ""}>
                            <Link to={"/secure/patients/" + patientId + "/vitals"}>Vitals</Link>
                        </li>
                        <li className={isActive("QUESTIONNAIRES") ? "is-active" : ""}>
                            <Link to={"/secure/patients/" + patientId + "/questionnaires"}>Questionnaires</Link>
                        </li>
                        <li className={isActive("SUGGESTIONS") ? "is-active" : ""}>
                            <Link to={"/secure/patients/" + patientId + "/suggestions"}>Suggestions</Link>
                        </li>
                    </ul>
                </div>
                <Switch>
                    {/*<Route path="/secure/patients/:patientId/details" component={PatientDetailsScreen}/>*/}
                    <Route path="/secure/patients/:patientId/questionnaires"
                           component={PatientQuestionnairesScreen}/>
                    <Route path="/secure/patients/:patientId/suggestions" component={PatientInterventionsScreen}/>
                    <Route path="/secure/patients/:patientId/vitals" component={PatientVitalsScreen}/>
                    <Redirect from="/secure/patients/:patientId" to="/secure/patients/:patientId/questionnaires"/>
                </Switch>
            </div>
        </React.Fragment>
    );
};
export default PatientScreen;