import React, { useState } from 'react';
import { PatientItem } from "../secure/model/patient";
import { Constants } from "../storage/constants";

interface Patient {
    patient: PatientItem,
    selectPatient: (patient: PatientItem) => void
}

const emptyPatient: PatientItem = {active: true, deceased: false, id: ""}
export const PatientContext = React.createContext<Patient>({
    patient: {...emptyPatient},
    selectPatient: () => {
    }
});

const getPatient = (): PatientItem => {
    const p = sessionStorage.getItem(Constants.PATIENT)
    return !!p ? JSON.parse(p) : emptyPatient
}

const sessionPatient = getPatient();

const PatientProvider = (props: any) => {
    const [patient, setPatient] = useState<PatientItem>(sessionPatient);

    function selectPatient(patient: PatientItem) {
        console.log("New patient: " + JSON.stringify(patient));
        sessionStorage.setItem(Constants.PATIENT, JSON.stringify(patient))
        setPatient(patient);
    }

    return (
        <PatientContext.Provider value={{patient, selectPatient}}>
            {props.children}
        </PatientContext.Provider>
    );
}

export default PatientProvider;