import { PagingRequest, PagingResponse } from "../../../components/paging/model";
import { CancerSystem, FhirEntry, FhirIdentifier, FhirResponse, IdentifierSystem } from "../../model/fhir";
import { CancerType } from "../../model/cancer";
import { PatientItem } from "../../model/patient";

export const PatientTransformer = (
    response: FhirResponse,
    request: PagingRequest
): PagingResponse<PatientItem> => {
    const pages = Math.floor((response.total / request.size))

    const items: PatientItem[] = []
    response.entry.forEach((e: FhirEntry) => {
        const r = e.resource
        const p: PatientItem = {
            id: r.identifier.find((s: FhirIdentifier) => s.system === IdentifierSystem)!.value,
            active: r.active!,
            deceased: r.deceasedBoolean!,
            cancer: r.identifier.find((s: FhirIdentifier) => s.system === CancerSystem)!.value as CancerType,
            gender: r.gender!,
            generalPractitioner: r.generalPractitioner
        }
        items.push(p)

    })
    const res = {
        totalItems: response.total,
        hasNextPage: pages > request.page,
        items: items
    }
    console.log(res)
    console.log(pages)
    console.log(request.page)
    return res
}