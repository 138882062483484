import React, { useContext, useEffect, useState } from "react";
import Portlet from "../../../components/portlet/Portlet";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { PatientContext } from "../../../context/PatientProvider";
import { PatientNeedScore, PatientQolResponse } from "../../model/patient";
import { AuthGet } from "../../../services/axios";
import { ClusteringContext } from "../../../context/ClusteringProvider";

const PatientDetailsBlock: React.FC = React.memo(() => {
    const {patientId} = useParams<{ patientId: string }>();

    const patientContext = useContext(PatientContext)
    const clusteringContext = useContext(ClusteringContext)

    const [needs, setNeeds] = useState<PatientQolResponse>();

    useEffect(() => {
        AuthGet('origin=hcp&destination=cloud&data[resourceType]=QoL&data[identifier]=' + patientId)
            .then((res: AxiosResponse<PatientQolResponse[]>) => {
                console.log(res.data)
                if (res.data.length > 0) {
                    setNeeds(res.data[0])
                }
            })
            .catch(err => console.log(err))
    }, [patientId])

    const getPatientValue = (v?: string) => {
        if (!!v) {
            return v
        }
        return "N/A"
    }

    const patientCluster = clusteringContext.get(patientId)

    return <React.Fragment>
        <Portlet>
            <div className="field is-grouped is-grouped-multiline">
                <div className="control">
                    <div className="tags has-addons">
                        <span className="tag is-dark is-large">Cancer type</span>
                        <span className="tag is-info is-large">{getPatientValue(patientContext.patient.cancer)}</span>
                    </div>
                </div>
                <div className="control">
                    <div className="tags has-addons">
                        <span className="tag is-dark is-large">Gender</span>
                        <span className="tag is-info is-large">{getPatientValue(patientContext.patient.gender)}</span>
                    </div>
                </div>
                {!!patientCluster &&
                    <div className="control tooltip has-tooltip-arrow arrow-down has-tooltip-bottom has-tooltip-multiline"
                         data-tooltip={patientCluster.Description}>
                        <div className="tags has-addons">
                            <span className="tag is-dark is-large">Cluster</span>
                            <span className="tag is-info is-large">{patientCluster.Label}</span>
                        </div>
                    </div>
                }


                {!!needs && <React.Fragment>
                    {!!needs.qol && <div className="control">
                        <div className="tags has-addons">
                            <span className="tag is-dark is-large">QoL</span>
                            <span className="tag is-info is-large">{1000 - needs.qol}</span>
                        </div>
                    </div>}

                    {!!needs.need_scores && needs.need_scores.map((n: PatientNeedScore) => {
                        if (!n.score) {
                            return <React.Fragment key={n.need}/>
                        }
                        return <React.Fragment key={n.need}>
                            <div className="control">
                                <div className="tags has-addons">
                                    <span className="tag is-dark is-large">{n.need}</span>
                                    <span className="tag is-info is-large">{n.score.toFixed(2)}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    })}
                </React.Fragment>}
            </div>
        </Portlet>
    </React.Fragment>
})
export default PatientDetailsBlock;