import React from "react";
import exus from "../../img/exus.png";
import europe from "../../img/flag_yellow_low.jpg";

const LayoutFooter: React.FC = React.memo(() => {
    return (
        <React.Fragment>
            <footer className="is-info footer g-footer">
                <div className="columns is-mobile">
                    <div className="column is-vcentered has-text-primary is-one-fifth">
                        <p>Created by</p>
                        <img src={exus} className="exus" alt="Exus logo" />
                        <p>&copy; 2020</p>
                    </div>
                    <div className="column is-centered has-text-centered has-text-primary">
                        <p>Co-funded by the Horizon 2020 Framework</p>
                        <p>Programme of the European Union </p>
                        <p>for Research and Innovation under </p>
                        <p>grant agreement no 875392</p>
                        <p><img src={europe} width="128" alt="EU flag" /></p>
                    </div>
                    <div className="column is-one-fifth"/>
                </div>
            </footer>
        </React.Fragment>
    );
});
export default LayoutFooter;