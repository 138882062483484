import { Index, TimeSeries } from 'pondjs';
import React, { useEffect, useState } from 'react';
import { BarChart, ChartContainer, ChartRow, Charts, Resizable, YAxis } from "react-timeseries-charts";
import { augmentPeriod } from "./utils";

export type ChartType = "series" | "line" | "bar"

interface StepsMeasurement {
    timestamp: number
    steps: number
}

const style = {
    steps: {
        normal: {
            fill: "steelblue",
            opacity: 0.8,
        },
        highlighted: {
            fill: "#a7c4dd",
            opacity: 1.0,
        },
        selected: {
            fill: "orange",
            opacity: 1.0,
        },
        muted: {
            fill: "grey",
            opacity: 0.5
        }
    }
}

type Period = "week" | "month" | "6months" | "year";
const calculateDaysPrior = (p: Period) => {
    switch (p) {
        case "week":
            return 7;
        case "month":
            return 30;
        case "6months":
            return 182;
        default:
            return 365;
    }
}

interface Props {
    measurements: StepsMeasurement[]
}

const StepsDataChart: React.FC<Props> = ({measurements}) => {
    const title = "Steps"

    const [period, setPeriod] = useState<Period>("week");

    function buildPoints(m: StepsMeasurement[], p: Period) {
        const prior = calculateDaysPrior(p)
        const beginDate = new Date()
        beginDate.setDate(new Date().getDate() - prior)
        const measurements: any[] = []
        m.forEach(mm => {
            if (new Date(mm.timestamp) > beginDate) {
                // @ts-ignore
                measurements.push([Index.getIndexString("1d", new Date(mm.timestamp)), mm.steps])
                // @ts-ignore
                console.log([Index.getIndexString("1d", new Date(mm.timestamp)), mm.steps])
            }
        })
        if (measurements.length === 0) {
            setPeriod(augmentPeriod(period))
        }
        return measurements
    }

    const createTimeSeries = (p: Period): TimeSeries => {
        return new TimeSeries({
            name: "Steps",
            columns: ["index", "steps"],
            points: buildPoints(measurements, p)
        })
    }

    const [stepsSeries, setStepsSeries] = useState<any>(createTimeSeries(period))

    useEffect(() => {
        setStepsSeries(createTimeSeries(period))
    }, [period])

    const isActive = (p: Period) => {
        return p === period;
    };

    return (
        <React.Fragment>
            <div>
                <p className="title is-5">{title}</p>
            </div>
            <div className="columns is-centered is-vcentered">
                <div className="column is-left">

                </div>
                <div className="column is-right has-text-right is-narrow">
                    <div className="tabs mb-0">
                        <ul id={title}>
                            <li className={isActive("week") ? "is-active" : ""}>
                                <a href={"#" + title} onClick={() => setPeriod("week")}>7 days</a>
                            </li>
                            <li className={isActive("month") ? "is-active" : ""}>
                                <a href={"#" + title} onClick={() => setPeriod("month")}>4 weeks</a>
                            </li>
                            <li className={isActive("6months") ? "is-active" : ""}>
                                <a href={"#" + title} onClick={() => setPeriod("6months")}>6 months</a>
                            </li>
                            <li className={isActive("year") ? "is-active" : ""}>
                                <a href={"#" + title} onClick={() => setPeriod("year")}>Year</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>

                {!!stepsSeries.range() &&
                    <React.Fragment>
                        <Resizable>
                            <ChartContainer
                                // title={title}
                                // titleStyle={{fill: "#555", fontWeight: 500}}
                                timeRange={stepsSeries.range()}
                                format="%d/%m"
                                // timeAxisTickCount={stepsSeries.size()}
                                // timeAxisAngledLabels
                                maxTime={stepsSeries.range().end()}
                                minTime={stepsSeries.range().begin()}

                                // onTrackerChanged={handleTrackerChanged}
                                // onMouseMove={(x: any, y: any) => handleMouseMove(x, y)}
                                // showGrid
                            >
                                <ChartRow height="200">
                                    <YAxis
                                        id="y"
                                        label={"Steps"}
                                        width="60"
                                        min={stepsSeries.min("steps", (v: any) => v)}
                                        max={stepsSeries.max("steps")}
                                        type="linear"
                                        hideAxisLine
                                        format={",.0f"}/>
                                    <Charts>
                                        <BarChart
                                            axis="y"
                                            style={style}
                                            spacing={1}
                                            columns={["steps"]}
                                            series={stepsSeries}
                                            // minBarHeight={1}
                                        />
                                    </Charts>
                                </ChartRow>
                            </ChartContainer>
                        </Resizable>

                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
};
export default StepsDataChart;