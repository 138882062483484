import React, { useContext, useEffect } from 'react';
import { Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import LayoutControlPanelLeft from '../../components/layout/LayoutControlPanelLeft';
import LayoutFooter from '../../components/layout/LayoutFooter';
import LayoutHeader from '../../components/layout/LayoutHeader';
import DashboardScreen from '../dashboard/DashboardScreen';
import PatientScreen from '../patients/PatientScreen';
import PatientsScreen from '../patients/PatientsScreen';
import QuestionnaireAnsweredScreen from '../questionnaires/QuestionnaireAnsweredScreen';
import { InternationalizationContext } from '../../context/InternationalizationProvider';
import { AxiosResponse } from "axios";
import { definitionsExist, getDefinitions, setDefinitions } from "../../storage/QuestionnaireDefinitions";
import { FhirEntry, FhirResponse } from "../model/fhir";
import { PractitionerContext } from "../../context/PractitionerProvider";
import { AuthGet, AuthRawPost } from "../../services/axios";
import ChangePassword from "../user/ChangePassword";
import NotificationsScreen from "../notifications/NotificationsScreen";
import { ClusteringContext } from "../../context/ClusteringProvider";

const SecureRouter: React.FC = () => {
    const context = useContext(InternationalizationContext);
    const practitionerContext = useContext(PractitionerContext);
    const clusteringContext = useContext(ClusteringContext)

    useEffect(() => {
        if (definitionsExist()) {
            return
        }


        AuthGet('origin=hcp&destination=fhir&data[resourceType]=Questionnaire&data[resourceLanguage]=IT')
            .then((res: AxiosResponse<FhirResponse>) => {
                let d: FhirEntry[] = [...getDefinitions()]
                res.data.entry.forEach(e => d.push(e))
                setDefinitions(d)
            })
            .catch(err => {
                console.log(err);
            })
        AuthGet('origin=hcp&destination=fhir&data[resourceType]=Questionnaire&data[resourceLanguage]=DE')
            .then((res: AxiosResponse<FhirResponse>) => {
                let d: FhirEntry[] = [...getDefinitions()]
                res.data.entry.forEach(e => d.push(e))
                setDefinitions(d)
            })
            .catch(err => {
                console.log(err);
            })
        AuthGet('origin=hcp&destination=fhir&data[resourceType]=Questionnaire&data[resourceLanguage]=EN')
            .then((res: AxiosResponse<FhirResponse>) => {
                let d: FhirEntry[] = [...getDefinitions()]
                res.data.entry.forEach(e => d.push(e))
                setDefinitions(d)
            })
            .catch(err => {
                console.log(err);
            })


        AuthGet('origin=hcp&destination=fhir&data[resourceType]=Practitioner')
            .then((res: AxiosResponse<FhirResponse>) => {
                practitionerContext.selectPractitioner(res.data.entry[0].resource)
            })
            .catch(err => console.log(err));

        AuthRawPost("/clustering", null)
            .then((res) => {
                console.log(res.data)
                clusteringContext.set(res.data)
            })
    }, []);

    return (
        <React.Fragment>
            <LayoutHeader locale={context.locale} setLanguage={context.selectLanguage}/>

            <main>
                <div className="columns mt-0 is-fullheight">
                    <div className="column is-narrow has-background-primary is-sidebar-menu">
                        <LayoutControlPanelLeft/>
                    </div>
                    <div className="column has-background-grey-lighter mr-4">
                        <Switch>
                            <Route
                                path="/secure/patients/:patientId/questionnaires/type/:type/answer/:questionnaireId"
                                component={QuestionnaireAnsweredScreen}/>
                            <Route path="/secure/dashboard" component={DashboardScreen}/>
                            <Route path="/secure/patients/:patientId" component={PatientScreen}/>
                            <Route path="/secure/patients" component={PatientsScreen}/>
                            <Route path="/secure/questionnaires/:questionnaireId"
                                   component={QuestionnaireAnsweredScreen}/>

                            <Route path="/secure/notifications" component={NotificationsScreen}/>
                            <Route path="/secure/change-password" component={ChangePassword}/>

                            <Redirect exact from="/secure" to="/secure/dashboard"/>
                        </Switch>
                    </div>
                </div>
            </main>
            <LayoutFooter/>
        </React.Fragment>
    );
};
export default SecureRouter;