import React, { useState } from 'react';
import { Constants } from "../storage/constants";

export interface ClusterInfo {
    Cluster: number
    Label: string
    Description: string
}

interface Clustering {
    get: (id: string) => ClusterInfo | undefined
    set: (clusters: Object) => void
}

export const ClusteringContext = React.createContext<Clustering>({
    get: (id) => {
        return undefined
    },
    set: (clusters) => {
    },
});

const getClustering = (): Object | undefined => {
    const p = sessionStorage.getItem(Constants.CLUSTERING)
    return !!p ? JSON.parse(p) : undefined
}

const sessionClustering = getClustering();

const ClusteringProvider = (props: any) => {
    const [clustering, setClustering] = useState<any>(sessionClustering);

    function set(clusters: Object) {
        console.log("Clusters: " + JSON.stringify(clusters));
        sessionStorage.setItem(Constants.CLUSTERING, JSON.stringify(clusters))
        setClustering(clusters);
    }

    function get(id: string) {
        if (clustering === undefined) {
            return undefined
        }
        return clustering[id as keyof typeof clustering] as ClusterInfo
    }

    return (
        <ClusteringContext.Provider value={{get, set}}>
            {props.children}
        </ClusteringContext.Provider>
    );
}

export default ClusteringProvider;