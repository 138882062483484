import { getKeycloak } from "react-router-keycloak";
import axios from "axios";
import {env} from "../env"

export const AuthGet = (query: string) => {
    const token = getKeycloak().idToken;
    const baseURL = env.REACT_APP_ORCHESTRATOR_URL;
    return axios.get(url(baseURL, query), {headers: headers(token)})
}

export const AuthPost = (query: string, request: any) => {
    const token = getKeycloak().idToken;
    const baseURL = env.REACT_APP_ORCHESTRATOR_URL;
    return axios.post(url(baseURL, query, true), request, {headers: headers(token)})
}

export const AuthRawPost = (query: string, request: any) => {
    const token = getKeycloak().idToken;
    const baseURL = env.REACT_APP_ORCHESTRATOR_URL;
    return axios.post(baseURL + query, request, {headers: headers(token)})
}

export const AuthPut = (query: string, request: any) => {
    const token = getKeycloak().idToken;
    const baseURL = env.REACT_APP_ORCHESTRATOR_URL;
    return axios.put(baseURL + query, request, {headers: headers(token)})
}

export const AuthDataAggrGet = (query: string) => {
    const token = getKeycloak().idToken;
    const baseURL = env.REACT_APP_DATA_AGGR_URL;
    return axios.get(baseURL + query, {headers: headers(token)})
}

const headers = (token: string) => {
    return {"Authorization": `Bearer ${token}`, "Content-Type": "application/json"}
}

const url = (baseURL: string, query: string, isPost: boolean = false): string => {
    return baseURL + (isPost ? "/input": "/output") + (query.startsWith("?") ? query : "?" + query)
}