import { faBackward, faForward, faUsers } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";

const LayoutControlPanelLeft: React.FC = () => {
    const location = useLocation();

    const [smallMenu, setSmallMenu] = useState<Boolean>(false);

    const menuIsActiveClass = (identifier: string) => {
        return location.pathname.startsWith(identifier) ? 'is-active' : '';
    };

    return (
        <React.Fragment>
            {smallMenu ?
                <aside className="is-hidden-touch sidebar" style={{ minWidth: "40px", maxWidth: "40px" }}>
                    <ul className={'menu-list has-text-centered py-1'}>
                        <li className="my-3">
                            <Link to="/secure/dashboard" className={menuIsActiveClass('/secure/dashboard')}>
                                <span><FontAwesomeIcon icon={faHome} /></span>
                            </Link>
                        </li>
                        <li className="my-3">
                            <Link to="/secure/patients" className={menuIsActiveClass('/secure/patients')}>
                                <span><FontAwesomeIcon icon={faUsers} /></span>
                            </Link>
                        </li>
                        <li className="my-3">
                            <Link to="/secure/notifications" className={menuIsActiveClass('/secure/notifications')}>
                                <span><FontAwesomeIcon icon={faBell} /></span>
                            </Link>
                        </li>
                        <li className="my-3">
                            <Link to="/secure/change-password" className={menuIsActiveClass('/secure/change-password')}>
                                <span><FontAwesomeIcon icon={faEdit} /></span>
                            </Link>
                        </li>
                    </ul>
                    <a onClick={() => setSmallMenu(!smallMenu)} className="icon has-text-grey"><FontAwesomeIcon icon={faForward} /></a>
                </aside> :
                <aside className={'is-hidden-touch sidebar'}>
                    <div className="v-menu">
                        <ul className={'menu-list'}>
                            <li>
                                <Link to="/secure/dashboard" className={menuIsActiveClass('/secure/dashboard')}>
                                    <span><FontAwesomeIcon icon={faHome} /></span>&nbsp;<span>Dashboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/secure/patients" className={menuIsActiveClass('/secure/patients')}>
                                    <span><FontAwesomeIcon icon={faUsers} /></span>&nbsp;<span>Patients</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/secure/notifications" className={menuIsActiveClass('/secure/notifications')}>
                                    <span><FontAwesomeIcon icon={faBell} /></span>&nbsp;<span>Notifications</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/secure/change-password" className={menuIsActiveClass('/secure/change-password')}>
                                    <span><FontAwesomeIcon icon={faEdit} /></span>&nbsp;<span>Change Password</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <a onClick={() => setSmallMenu(!smallMenu)} className="icon has-text-grey"><FontAwesomeIcon icon={faBackward} /></a>
                </aside>}
        </React.Fragment>
    );
};
export default LayoutControlPanelLeft;
