import {
    FhirEntry,
    FhirIdentifier,
    FhirResponse,
    InterventionGroupSystem,
    PatientNeedIdentifier
} from "../../model/fhir";
import {
    Condition,
    EmptyInterventionCondition,
    Intervention,
    InterventionCondition,
    InterventionLanguages,
    Interventions
} from "../model/interventions";
import cloneDeep from "lodash/cloneDeep"

export const ActivityDefinitionTransformer = (response: FhirResponse): Interventions => {
    const crc: InterventionLanguages = {
        en: cloneDeep<InterventionCondition>(EmptyInterventionCondition),
        de: cloneDeep<InterventionCondition>(EmptyInterventionCondition),
        it: cloneDeep<InterventionCondition>(EmptyInterventionCondition)
    };
    const aml: InterventionLanguages = {
        en: cloneDeep<InterventionCondition>(EmptyInterventionCondition),
        de: cloneDeep<InterventionCondition>(EmptyInterventionCondition),
        it: cloneDeep<InterventionCondition>(EmptyInterventionCondition)
    };
    response.entry.forEach((e: FhirEntry) => {
        const r = e.resource;
        const interventionGroup = r.identifier.find((i: FhirIdentifier) => i.system === InterventionGroupSystem);
        const condition = r.identifier.find((i: FhirIdentifier) => i.system === PatientNeedIdentifier);
        const formattedCondition = formatIncomingCondition(condition!.value);
        const intervention: Intervention = {
            id: r.name as string,
            name: r.title!,
            cancerType: "AML",
            condition: formattedCondition
        };
        if (interventionGroup!.value === "GROUP_AML") {
            let intCond: InterventionCondition = aml[r.language as keyof InterventionLanguages]!;
            intCond[formattedCondition as keyof InterventionCondition].push(intervention);
        } else {
            intervention.cancerType = "CRC"
            let intCond: InterventionCondition = crc[r.language as keyof InterventionLanguages]!;
            intCond[formattedCondition as keyof InterventionCondition].push(intervention);
        }
    })
    return {crc: crc, aml: aml};
}

const formatIncomingCondition = (condition: string): Condition => {
    condition = condition.trim()
    condition = condition.replaceAll(" - ", "_")
    condition = condition.replaceAll(" ", "_");
    condition = condition.replaceAll("-", "_");
    return condition as Condition;
}