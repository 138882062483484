import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getKeycloak } from 'react-router-keycloak';

import {
    Condition,
    ConditionDetails,
    ConditionsMap,
    Intervention,
    InterventionCondition,
    InterventionLanguages,
    InterventionResponse,
    Interventions
} from './model/interventions';

import { ActivityDefinitionTransformer } from "./transformers/activity-definition-transformer";
import { PatientContext } from "../../context/PatientProvider";
import { InternationalizationContext } from "../../context/InternationalizationProvider";
import Portlet from "../../components/portlet/Portlet";
import Modal from "../../components/modal/Modal";
import useModal from "../../components/modal/useModal";
import { PractitionerContext } from "../../context/PractitionerProvider";
import { AuthGet, AuthPost } from "../../services/axios";

// todo(romanos): fix types
interface Entry {
    Title: string
    Timestamp: number
    Condition: string
    Feedback: string
    RID: number
    QNID: number
}

const PatientInterventionsScreen: React.FC = () => {
    const {patientId} = useParams<{ patientId: string }>();
    const [entries, setEntries] = useState<InterventionResponse[]>();
    const [condition, setCondition] = useState<string>("EMPTY");

    const [interventions, setInterventions] = useState<Intervention[]>();
    const [interventionData, setInterventionData] = useState<InterventionCondition>();
    const [availableConditions, setAvailableConditions] = useState<ConditionDetails[]>()
    const [selectedIntervention, setSelectedIntervention] = useState<string>("EMPTY");
    const [showEmptyWarning, setShowEmptyWarning] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const interventionModal = useModal();

    const patientContext = useContext(PatientContext);
    const practitionerContext = useContext(PractitionerContext)
    const languageContext = useContext(InternationalizationContext);

    useEffect(() => {
        AuthGet('origin=hcp&destination=fhir&data[resourceType]=ActivityDefinition&data[_count]=1000&type=sync&notifyUrl')
            .then(res => {
                const raw: Interventions = ActivityDefinitionTransformer(res.data);
                const cancer = !!patientContext.patient.cancer ? patientContext.patient.cancer : 'aml'
                const cancerSpecificInterventions = raw[cancer.toLowerCase() as keyof Interventions]
                if (!cancerSpecificInterventions) {
                    return;
                }
                const data = cancerSpecificInterventions[languageContext.locale.toLowerCase() as keyof InterventionLanguages]!
                const conditions = createConditionList(data);
                setAvailableConditions(conditions);
                setInterventionData(data);
            })
    }, [languageContext.locale, patientContext.patient]);

    const createConditionList = (data: InterventionCondition): ConditionDetails[] => {
        const conditionKeys = Object.keys(data!);
        const conditions: ConditionDetails[] = []
        conditionKeys.forEach(k => {
            const d = data[k as keyof InterventionCondition]
            if (!d || d.length === 0) {
                return;
            }
            if (!!conditions.find(c => c.key === k)) {
                return
            }
            conditions.push(ConditionsMap[k])
        });
        return conditions
    }

    const getInterventions = React.useCallback(() => {
        setIsLoading(true);
        console.log('GetInterventions')
        AuthGet('origin=hcp&destination=cloud&data[resourceType]=Interventions&data[identifier]=' + patientId)
            .then(res => {
                console.log(res.data);
                setEntries(!!res.data.intervention ? res.data.intervention : []);
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }, [patientId]);

    useEffect(() => {
        setSelectedIntervention("EMPTY");
        setInterventions(undefined);
        if (!!condition && !!interventionData) {
            console.log(condition)
            const list = interventionData![condition as keyof InterventionCondition];
            console.log(list)
            if (!!list) {
                setShowEmptyWarning(false);
            } else {
                setShowEmptyWarning(true);
            }
            setInterventions(list);
        }
    }, [condition, interventionData]);

    useEffect(() => {
        getInterventions();
    }, [getInterventions]);

    const onCreateClicked = () => {
        interventionModal.open();
    };

    const testSaveIntervention = () => {
        const token = getKeycloak().idToken;
        const t = {
            origin: "hcp",
            destination: "fhir",
            data: {
                resourceType: "ServiceRequest",
                ActivityDefinition: selectedIntervention,
                Patient: patientId
            },
        }
        console.log(t);
        AuthPost('', t)
            .then(res => {
                console.log(res.data);
                interventionModal.close();
                reset();
                setEntries(undefined);
                getInterventions();
            })
            .catch(err => console.log(err));
    };

    const handleSelectIntervention = (code: string) => {
        const i = interventions!.find(ii => ii.name === code);
        if (!i) {
            return;
        }
        setSelectedIntervention(i.id!);
    };

    const reset = () => {
        setSelectedIntervention("EMPTY");
        setInterventions(undefined);
        setCondition("EMPTY");
    }

    const findCondition = (short: string) => {
        const keyNames = Object.keys(ConditionsMap);
        const key = keyNames.find(k => ConditionsMap[k].short === short)
        if (!key) {
            return {label: short}
        }
        return ConditionsMap[key!]
    }

    const newInterventionButton = () => {
        if (practitionerContext.isPatientOf(patientContext.patient.generalPractitioner!)) {
            return <button onClick={onCreateClicked} className="button is-primary is-outlined">
                <span><FontAwesomeIcon icon={faPlus}/>&nbsp;Create</span>
            </button>
        }
        return undefined
    }

    return <React.Fragment>
        <Portlet title="Suggestions"
                 subtitle={!!entries ? entries.length + '' : undefined}
                 noPadding
                 isLoading={isLoading}
                 actions={newInterventionButton()}>

            {(!entries || entries.length === 0) && <div className="notification is-info is-light m-3">
                There are no suggestions for the patient!!!
            </div>}

            {!!entries && entries.length > 0 &&
                <div className="table-wrapper">
                    <table className="table is-fullwidth is-hoverable mb-0">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Condition</th>
                            <th>Feedback</th>
                            <th style={{width: "100px"}}>Date</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {entries.map((entry: InterventionResponse, index: number) => {
                            return <tr key={index}>
                                <td className="is-vcentered">
                                    <span>{index + 1}</span>
                                </td>
                                <td className="is-vcentered">
                                    <span>{entry.Title}</span>
                                </td>
                                <td className="is-vcentered">
                                    <span>{findCondition(entry.Condition).label}</span>
                                </td>
                                <td className="is-vcentered">
                                    <span>{entry.Feedback}</span>
                                </td>
                                <td className="is-vcentered">
                                    <span>{new Date(entry.Timestamp * 1000).toLocaleDateString()}</span>
                                </td>
                                <td className="is-vcentered">
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>}
        </Portlet>

        <Modal title="Create suggestion for patient"
               ref={interventionModal.ref}
               actions={<button className="button is-primary" onClick={testSaveIntervention}>Submit</button>}
               onClose={reset}>
            <form>
                <div className="field">
                    <label className="label">Condition</label>
                    <div className="control">
                        <div className="select" style={{width: "100%"}}>
                            <select style={{width: "100%"}}
                                    name="condition"
                                    defaultValue="EMPTY"
                                    onChange={v => setCondition(v.currentTarget.value as Condition)}
                            >
                                <option value="EMPTY"/>
                                {!!availableConditions && availableConditions.map((c: ConditionDetails) => {
                                    return <option key={c.name} value={c.key}>{c.label}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>

                {showEmptyWarning &&
                    <div className="notification is-warning">There are no suggestions for this condition!!</div>}

                {!!interventions &&
                    <div className="field">
                        <label className="label">Suggestion</label>
                        <div className="control">
                            <div className="select" style={{width: "100%"}}>
                                <select style={{width: "100%"}}
                                        defaultValue="EMPTY"
                                        name="intervention"
                                        onChange={(v) => handleSelectIntervention(v.currentTarget.value)}>
                                    <option value="EMPTY"/>
                                    {interventions.map((intervention: Intervention) => {
                                        return <option key={intervention.name}
                                                       value={intervention.name}>{intervention.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                }
                {/* {!!options && <div className="field">
                    <label className="label">Options</label>
                    <div className="control"></div><ul>
                        {options.map((o, i) => {
                            return <span key={o}>{i !== 0 && <span>,&nbsp;</span>}{o}</span>
                        })}
                    </ul>
                </div>
                } */}
            </form>
        </Modal>
    </React.Fragment>
};
export default PatientInterventionsScreen;